import { createRouter,createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/Index.vue'),
    children:[
      {
        path: '/test-web-info',
        name: 'testWebInfo',
        component: () => import('@/views/test/TestWebInfo.vue')
      },
      //  拦截4040
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/NotFound.vue')
      },
      /**
       * 病例主页
       */
      {
        path:'/case',
        name:'Case',
        component: ()=>import('@/views/case/Index')
      },
      /**
       * 病例先详情
       */
      {
        path: '/case/detail',
        name:'CaseDetail',
        component: ()=>import('@/views/case/CasePatientDetail')
      },
      /**
       * 选择病例模板列表
       */
      {
        path: '/case/templates',
        name:`CaseTemplate`,
        component: ()=>import('@/views/case/CaseTemplates')
      }

    ]
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
