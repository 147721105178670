import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)
import { Button ,NavBar,Search,List,Sticky,Icon,
    Loading,Popup,Cell, CellGroup,Image as VanImage} from 'vant';
const app = createApp(App);
app.use(router).use(pinia).mount('#app')

app.use(Button).use(NavBar).use(Search).
use(List).use(Sticky).use(Icon).use(Loading).
use(Popup).use(Cell).use(CellGroup).use(VanImage)
